import AddIcon from '@mui/icons-material/Add';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import RemoveIcon from '@mui/icons-material/Remove';
import { Button, ButtonGroup, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import {
  IOSMDOptions,
  Note,
  OpenSheetMusicDisplay as OSMD,
  PointF2D,
} from 'osmd-extended';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Soundfont from 'soundfont-player';

import { selectGlobalSettings } from '../../../redux/slices/globalSettingsSlice';

import {
  selectOSMDNotesOnStr,
  updateOneMidiChannel,
} from '../../../redux/slices/midiListenerSlice';
import {
  selectOSMDCursorNotes,
  updateOSMDState,
} from '../../../redux/slices/osmdStateSlice';
import { useAppDispatch, useTypedSelector } from '../../../redux/store';
import { useMsStyles } from '../../../styles/styleHooks';
import LoadingOverlay from '../../utilComponents/LoadingOverlay';
import OSMDSettings from './OSMDSettings';
import {
  addPlaybackControl,
  errorLoadingOrRenderingSheet,
  OSMDBlockButtons,
  OSMDFileSelector,
  useOSMDStyles,
  withOSMDFile,
} from './OSMDUtils';
import { InteractionType } from 'osmd-extended/build/dist/src/Common/Enums/InteractionType';

export interface SheetMusicWidgetProps {
  block: MidiBlockT;
  osmdFile: any;
  hover: boolean;
}

const SheetMusicWidget = React.memo(
  ({ block, osmdFile, hover }: SheetMusicWidgetProps) => {
    const { channelId, osmdSettings, colorSettings, themeMode } = block;

    const containerDivId = `osmd-container-${block.id}`;
    const dispatch = useAppDispatch();
    const osmd = useRef<OSMD>();
    const soundfontManager = useRef<SoundfontManager>({
      metronomeSF: {} as Soundfont.Player,
      pianoSF: {} as Soundfont.Player,
    });
    const globalSettings = useTypedSelector(selectGlobalSettings);
    const osmdNotesOnStr = useTypedSelector((state) =>
      selectOSMDNotesOnStr(state, channelId, osmdSettings.iterateCursorOnInput)
    );
    const osmdCursorNotes = useTypedSelector(selectOSMDCursorNotes);
    const strCursorNotes = JSON.stringify(osmdCursorNotes);
    const [osmdLoadingState, setOSMDLoadingState] = useState<
      'uninitiated' | 'loading' | 'complete'
    >('uninitiated');
    const [currentBpm, setCurrentBpm] = useState(120);

    const [osmdError, setOSMDError] = useState('');

    // theme vars
    const muiTheme = useTheme();
    const classes = useOSMDStyles();
    const msClasses = useMsStyles();
    let backgroundColor = muiTheme.palette.background.paper;
    let textColor = muiTheme.palette.text.primary;
    let mainColor = muiTheme.palette.primary.main;
    if (themeMode === 'default') {
      backgroundColor = '#fff';
      textColor = '#0C0C0C';
    }
    const cursorAlpha = 0.4;

    // get the notes under the cursor and set cursorNotes state
    const updateCursorNotes = useCallback(() => {
      if (osmd?.current?.cursor) {
        let newNotes: number[] = [];
        osmd.current.cursor.NotesUnderCursor().forEach((note: Note) => {
          const midiNoteNum = note.halfTone + 12;
          const tiedNote = note?.NoteTie && note.NoteTie.Notes[0] !== note;
          const noteInCursorMatchClefs =
            osmdSettings.cursorMatchClefs === 'Treble'
              ? note.ParentStaffEntry.ParentStaff.Id === 1
              : osmdSettings.cursorMatchClefs === 'Bass'
              ? note.ParentStaffEntry.ParentStaff.Id === 2
              : true;
          // make sure rests, duplicates and hidden notes are not included
          if (
            !note.isRest() &&
            !tiedNote &&
            !newNotes.includes(midiNoteNum) &&
            note.PrintObject &&
            noteInCursorMatchClefs
          ) {
            newNotes.push(midiNoteNum);
          }
        });
        // sort the notes from lowest to highest
        const sortedNewNotes = newNotes.sort((a, b) => a - b);
        const stringifiedNotes = JSON.stringify(sortedNewNotes);
        dispatch(
          updateOSMDState({
            osmdCursorNotes: sortedNewNotes,
          })
        );
        return stringifiedNotes;
      }
      return '[]';
    }, [osmdSettings.cursorMatchClefs, dispatch]);

    // initialize and render OSMD
    useEffect(() => {
      (async () => {
        setOSMDLoadingState('loading');
        // add short delay to make sure loading state is updated before blocking osmd load executes
        await (() => new Promise((resolve) => setTimeout(resolve, 50)))();
        setOSMDError('');
        let osmdOptions: IOSMDOptions = {
          autoResize: false,
          backend: 'svg', // svg, canvas
          followCursor: true,
          defaultColorMusic: textColor,
          // darkMode: true,
          defaultColorRest: textColor,
          colorStemsLikeNoteheads: true,
          drawTitle: osmdSettings.drawTitle,
          drawFromMeasureNumber: osmdSettings.drawFromMeasureNumber,
          drawUpToMeasureNumber: osmdSettings.drawUpToMeasureNumber,
          cursorsOptions: [
            {
              type: 0,
              alpha: cursorAlpha,
              color: mainColor,
              follow: true,
            },
          ],
        };

        osmd.current = new OSMD(containerDivId, osmdOptions);
        await osmd?.current
          ?.load(osmdFile)
          .then(
            () => {
              // set instance variables and render
              if (osmd?.current?.IsReadyToRender()) {
                osmd.current.zoom = osmdSettings.zoom;
                osmd.current.DrawingParameters.setForCompactTightMode();
                osmd.current.DrawingParameters.Rules.MinimumDistanceBetweenSystems = 15;
                osmd.current.EngravingRules.PageBackgroundColor =
                  backgroundColor;
                // update cursor notes when user clicks to select new note(s)
                osmd.current.RenderingManager.addListener({
                  userDisplayInteraction: (
                    positionInSheetUnits: PointF2D,
                    relativePosition: PointF2D,
                    type: InteractionType
                  ) => {
                    updateCursorNotes();
                  },
                });
                return osmd.current.render();
              } else {
                console.error('OSMD tried to render but was not ready!');
              }
            },
            (e: Error) => {
              errorLoadingOrRenderingSheet(e, 'rendering');
            }
          )
          .then(
            () => {
              // after rendering, set cursor notes and bpm
              if (osmd?.current) {
                if (osmdSettings.showCursor) {
                  osmd.current.cursor.show();
                  setCurrentBpm(osmd.current.Sheet.DefaultStartTempoInBpm);
                  addPlaybackControl(
                    osmd.current,
                    osmdSettings.drawFromMeasureNumber,
                    osmdSettings.playbackVolume,
                    osmdSettings.metronomeVolume,
                    soundfontManager.current,
                    osmdSettings.midiOutputId,
                    osmdSettings.midiOutputChannel
                  ).then(updateCursorNotes);
                } else {
                  osmd.current.cursor?.hide();
                }
                setOSMDLoadingState('complete');
              }
            },
            (e: Error) => {
              errorLoadingOrRenderingSheet(e, 'loading');
            }
          )
          .catch((e) => {
            setOSMDError(
              'Unable to load selected file.\nPlease make sure the file you selected is valid MusicXML.'
            );
          });
      })();
      return () => {
        if (osmd?.current) {
          // unmount cleanup
          osmd.current.PlaybackManager?.pause();
          osmd.current = undefined;
          // make sure the container is empty (hot-loading was causing issue)
          const containerDiv = document.getElementById(containerDivId);
          if (containerDiv?.hasChildNodes()) {
            containerDiv.innerHTML = '';
          }
        }
      };
    }, [
      osmdSettings.drawTitle,
      osmdSettings.drawFromMeasureNumber,
      osmdSettings.drawUpToMeasureNumber,
      osmdSettings.zoom,
      osmdSettings.showCursor,
      osmdSettings.colorNotes,
      backgroundColor,
      textColor,
      cursorAlpha,
      mainColor,
      colorSettings,
      osmdFile,
      containerDivId,
      osmdSettings.playbackVolume,
      osmdSettings.metronomeVolume,
      updateCursorNotes,
      osmdSettings.midiOutputId,
      osmdSettings.midiOutputChannel,
    ]);

    // rerender osmd when rerenderId changes
    useEffect(() => {
      osmd.current?.render();
      if (osmdSettings.showCursor) {
        osmd.current?.cursor.show();
      }
    }, [osmdSettings.rerenderId, osmdSettings.showCursor]);

    // increment osmd.cursor, update PlaybackManager iterator to match it, and update cursor notes
    const incrementCursor = useCallback(
      (cursorNext = true) => {
        if (osmd?.current) {
          if (cursorNext) osmd.current.cursor.next();
          // update current cursor notes
          const stringifiedNotes = updateCursorNotes();
          // if end is reached then reset back to start and update cursor notes
          if (osmd.current.cursor.Iterator.EndReached) {
            osmd.current.PlaybackManager?.setPlaybackStart(
              osmd.current.Sheet.SourceMeasures[
                Math.max(0, osmdSettings.drawFromMeasureNumber - 1)
              ].AbsoluteTimestamp
            );
            updateCursorNotes();
          }
          // skip over all rest notes when incrementing cursor
          else if (stringifiedNotes === '[]') {
            incrementCursor();
          }
        }
      },
      [osmdSettings.drawFromMeasureNumber, updateCursorNotes]
    );

    // increment osmd.cursor, and update cursor notes
    const decrementCursor = useCallback(
      (cursorNext = true) => {
        if (osmd?.current && !osmd.current.cursor.Iterator.FrontReached) {
          if (cursorNext) osmd.current.cursor.previous();
          // update current cursor notes
          const stringifiedNotes = updateCursorNotes();

          // skip over all rest notes when incrementing cursor
          if (stringifiedNotes === '[]') {
            decrementCursor();
          }
        }
      },
      [updateCursorNotes]
    );

    // iterate cursor to next step if the current strCursorNotes matches channel.osmdNotesOn
    useEffect(() => {
      // console.log('osmdNotesOnStr / strCursorNotes ', osmdNotesOnStr, strCursorNotes);
      if (
        osmdSettings.iterateCursorOnInput &&
        osmdSettings.showCursor &&
        osmd?.current?.cursor &&
        !osmd.current.cursor.Iterator.EndReached &&
        osmd.current.PlaybackManager?.RunningState === 0 &&
        ['[]', osmdNotesOnStr].includes(strCursorNotes)
      ) {
        // empty osmdNotesOn before cursor.next() so the notes must be pressed again before triggering the following next()
        dispatch(
          updateOneMidiChannel({
            id: channelId,
            changes: {
              osmdNotesOn: [],
            },
          })
        );
        incrementCursor();
      }
    }, [
      osmdSettings.iterateCursorOnInput,
      osmdSettings.showCursor,
      strCursorNotes,
      osmdNotesOnStr,
      channelId,
      dispatch,
      incrementCursor,
    ]);

    const updateBpm = (bpmDiff: number) => () => {
      const newBpm = currentBpm + bpmDiff;
      setCurrentBpm(newBpm);
      if (osmd?.current) {
        osmd.current.PlaybackManager?.bpmChanged(newBpm, true);
      }
    };

    // pause player, increment osmd.cursor until it reaches PlaybackManager timestamp, update cursor notes
    const pauseAudioPlayer = useCallback(() => {
      if (osmd?.current) {
        osmd.current.PlaybackManager?.pause();
        while (
          osmd.current.cursor.Iterator.currentTimeStamp.RealValue <
          osmd.current.PlaybackManager?.CursorIterator.currentTimeStamp
            .RealValue
        ) {
          osmd.current.cursor.next();
        }
        // update cursor notes and playback manager cursor but dont call cursor.next
        incrementCursor(false);
      }
    }, [incrementCursor]);

    const startAudioPlayer = useCallback(
      (seekMs?: number) => {
        const startPlay = () => {
          // playDummySound is required to get the PlaybackManager to work on iOS
          osmd.current?.PlaybackManager?.playDummySound();
          if (seekMs !== undefined) {
            osmd.current?.PlaybackManager?.playFromMs(seekMs);
          } else {
            osmd.current?.PlaybackManager?.play();
          }
        };
        // if metronomeCountInBeats is set then play a count in before starting playback
        if (
          osmdSettings.metronomeCountInBeats &&
          osmdSettings.metronomeVolume
        ) {
          for (let i = 1; i < osmdSettings.metronomeCountInBeats + 1; i++) {
            setTimeout(() => {
              soundfontManager?.current?.metronomeSF.play('C4', undefined, {
                gain: 5 * (osmdSettings.metronomeVolume / 100),
              });
            }, ((60 * 1000) / currentBpm) * i);
          }

          setTimeout(
            () => startPlay(),
            (osmdSettings.metronomeCountInBeats + 1) *
              ((60 * 1000) / currentBpm)
          );
        } else {
          startPlay();
        }
      },
      [
        osmdSettings.metronomeCountInBeats,
        osmdSettings.metronomeVolume,
        currentBpm,
      ]
    );

    // handle playbackIsPlaying changes
    useEffect(() => {
      if (osmdSettings.listenGlobalPlayback) {
        if (globalSettings.playbackIsPlaying) {
          startAudioPlayer(1000 * globalSettings.playbackSeekSeconds);
        } else {
          pauseAudioPlayer();
        }
      }
    }, [
      globalSettings.playbackIsPlaying,
      startAudioPlayer,
      pauseAudioPlayer,
      globalSettings.playbackSeekSeconds,
      osmdSettings.listenGlobalPlayback,
    ]);

    // handle playbackSeekVersion changes
    useEffect(() => {
      if (osmdSettings.listenGlobalPlayback) {
        osmd.current?.PlaybackManager?.playFromMs(
          1000 * globalSettings.playbackSeekSeconds
        ).then(() => {
          if (globalSettings.playbackSeekAutoplay === false) {
            pauseAudioPlayer();
          }
        });
      }
    }, [
      globalSettings.playbackSeekSeconds,
      globalSettings.playbackSeekAutoplay,
      pauseAudioPlayer,
      globalSettings.playbackSeekVersion,
      osmdSettings.listenGlobalPlayback,
    ]);

    // move cursor to the first measure
    const onCursorReset = () => {
      if (osmd?.current) {
        osmd.current.PlaybackManager?.setPlaybackStart(
          osmd.current.Sheet.SourceMeasures[
            Math.max(0, osmdSettings.drawFromMeasureNumber - 1)
          ].AbsoluteTimestamp
        );
        osmd.current.cursor.update();
        updateCursorNotes();
      }
    };

    return (
      <Box
        className={classes.container}
        sx={{ backgroundColor: backgroundColor }}
      >
        <div id={containerDivId} />
        {osmdError ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <Typography>{osmdError}</Typography>
            <Box
              sx={{
                width: '100%',
                margin: 'auto',
              }}
            >
              <OSMDFileSelector
                osmdSettings={osmdSettings}
                blockId={block.id}
              />
            </Box>
          </Box>
        ) : (
          <>
            {osmdLoadingState !== 'complete' && (
              <LoadingOverlay animate={false} />
            )}
            {osmdSettings.showCursor && (
              <Box
                className={classes.osmdButtonCont}
                sx={{
                  visibility: hover ? 'inherit' : 'hidden',
                }}
              >
                <Tooltip arrow title="Reset Cursor" placement="top">
                  <Button
                    variant="contained"
                    color="primary"
                    className={msClasses.iconButton}
                    onClick={onCursorReset}
                    aria-label="reset"
                  >
                    <FirstPageIcon />
                  </Button>
                </Tooltip>
                <Tooltip arrow title="Cursor Previous" placement="top">
                  <Button
                    variant="contained"
                    color="primary"
                    className={msClasses.iconButton}
                    onClick={() => decrementCursor()}
                    aria-label="previous"
                  >
                    <NavigateBeforeIcon />
                  </Button>
                </Tooltip>
                <Tooltip arrow title="Cursor Next" placement="top">
                  <Button
                    variant="contained"
                    color="primary"
                    className={msClasses.iconButton}
                    onClick={() => incrementCursor()}
                    aria-label="next"
                  >
                    <NavigateNextIcon />
                  </Button>
                </Tooltip>
                <Tooltip arrow title="BPM" placement="top">
                  <ButtonGroup
                    className={classes.buttonGroup}
                    disableElevation
                    variant="contained"
                  >
                    <Button
                      color="primary"
                      className={classes.buttonGroupItem}
                      sx={{
                        borderTopLeftRadius: '50%',
                        borderBottomLeftRadius: '50%',
                      }}
                      onClick={updateBpm(-5)}
                    >
                      <RemoveIcon />
                    </Button>
                    <Box color="primary" className={classes.buttonGroupText}>
                      {currentBpm}
                    </Box>
                    <Button
                      color="primary"
                      className={classes.buttonGroupItem}
                      sx={{
                        borderTopRightRadius: '50%',
                        borderBottomRightRadius: '50%',
                      }}
                      onClick={updateBpm(5)}
                    >
                      <AddIcon />
                    </Button>
                  </ButtonGroup>
                </Tooltip>
                <Tooltip arrow title="Pause" placement="top">
                  <Button
                    variant="contained"
                    color="primary"
                    className={msClasses.iconButton}
                    onClick={pauseAudioPlayer}
                    aria-label="pause"
                  >
                    <PauseIcon />
                  </Button>
                </Tooltip>
                <Tooltip arrow title="Play" placement="top">
                  <Button
                    variant="contained"
                    color="primary"
                    className={msClasses.iconButton}
                    onClick={() => startAudioPlayer()}
                    aria-label="play"
                  >
                    <PlayArrowIcon />
                  </Button>
                </Tooltip>
              </Box>
            )}
          </>
        )}
      </Box>
    );
  }
);

const exportObj: WidgetModule = {
  name: 'Sheet Music',
  Component: withOSMDFile(SheetMusicWidget),
  SettingComponent: OSMDSettings,
  ButtonsComponent: OSMDBlockButtons,
  defaultSettings: {}, // osmdSettings is handled on its own (not using widgetSettings)
  includeBlockSettings: ['Midi Input', 'Block Theme'],
  orderWeight: 8, // used to determine the ordering of the options in the Widget selector
};

export default exportObj;
