import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

const customThemeAdapter = createEntityAdapter<CustomTheme>({
  selectId: (customTheme) => customTheme.id,
});

const initialState = customThemeAdapter.getInitialState();

const customThemeSlice = createSlice({
  name: 'customTheme',
  initialState,
  reducers: {
    addCustomTheme: customThemeAdapter.addOne,
    upsertOneCustomTheme: customThemeAdapter.upsertOne,
    upsertManyCustomTheme: customThemeAdapter.upsertMany,
    updateManyCustomTheme: customThemeAdapter.updateMany,
    setAllCustomTheme: customThemeAdapter.setAll,
    removeCustomTheme: customThemeAdapter.removeOne,
  },
});

export const {
  addCustomTheme,
  upsertOneCustomTheme,
  upsertManyCustomTheme,
  updateManyCustomTheme,
  setAllCustomTheme,
  removeCustomTheme,
} = customThemeSlice.actions;

export const { selectAll: selectAllCustomThemes } =
  customThemeAdapter.getSelectors<RootState>((state) => state.customTheme);

export default customThemeSlice.reducer;
