/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTemplate = /* GraphQL */ `
  query GetTemplate($id: ID!) {
    getTemplate(id: $id) {
      id
      template
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listTemplates = /* GraphQL */ `
  query ListTemplates(
    $filter: ModelTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        template
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getCustomTheme = /* GraphQL */ `
  query GetCustomTheme($id: ID!) {
    getCustomTheme(id: $id) {
      id
      customTheme
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCustomThemes = /* GraphQL */ `
  query ListCustomThemes(
    $filter: ModelCustomThemeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomThemes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customTheme
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
