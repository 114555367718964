import AddIcon from '@mui/icons-material/Add';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteIcon from '@mui/icons-material/Delete';
import DragHandleOutlinedIcon from '@mui/icons-material/DragHandleOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Button, Menu, MenuItem, Tooltip } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Box } from '@mui/system';
import { MouseEvent, useState } from 'react';
import { Layout } from 'react-grid-layout';
import { useResizeDetector } from 'react-resize-detector';
import { openDrawer } from '../../redux/slices/drawerContainerSlice';
import { selectGlobalThemeMode } from '../../redux/slices/globalSettingsSlice';
import {
  selectJoyrideTour,
  updateJoyrideTour,
} from '../../redux/slices/joyrideTourSlice';
import {
  addMidiBlockAndLayout,
  removeMidiBlockAndLayout,
  selectMidiBlockById,
} from '../../redux/slices/midiBlockSlice';
import { useAppDispatch, useTypedSelector } from '../../redux/store';

import { useCustomTheme, useMsStyles } from '../../styles/styleHooks';
import { useWidgetModules } from '../../utils/hooks';
import { getDefaultMidiBlock, isTouchDevice } from '../../utils/utils';

interface MidiBlockProps {
  blockLayout: Layout;
  deleteDisabled: boolean;
  blockIndex: number;
}

const MidiBlock = ({
  blockLayout,
  deleteDisabled,
  blockIndex,
}: MidiBlockProps) => {
  const blockId = blockLayout.i;
  const msClasses = useMsStyles();
  const { widgetModules } = useWidgetModules();
  const { width, height, ref } = useResizeDetector({
    refreshMode: 'debounce',
    refreshRate: 500,
  });
  const dispatch = useAppDispatch();
  const [hover, setHover] = useState(false);
  const block = useTypedSelector((state) =>
    selectMidiBlockById(state, blockId)
  );
  const joyrideTour = useTypedSelector(selectJoyrideTour);
  const [deleteAnchorEl, setDeleteAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const openDeleteMenu = Boolean(deleteAnchorEl);
  // use globalThemeMode if block's themeMode is 'default', else use block themeMode
  const globalThemeMode = useTypedSelector(selectGlobalThemeMode);
  let blockThemeMode =
    block?.themeMode && block.themeMode !== 'default'
      ? block.themeMode
      : globalThemeMode;
  const theme = useCustomTheme(blockThemeMode);

  const handleHoverEvent = (value: boolean) => () => {
    if (!isTouchDevice) {
      setHover(value);
    }
  };

  if (!block) {
    return null;
  }

  const openBlockSettings = () => {
    dispatch(
      openDrawer({
        drawerId: 'BLOCK_SETTINGS',
        drawerData: { blockId },
        tabValue: 0,
      })
    );
    if (joyrideTour.tour === 'GET_STARTED') {
      dispatch(
        updateJoyrideTour({
          stepIndex: joyrideTour.stepIndex + 1,
        })
      );
    }
  };

  const addNewBlock = () => {
    const newBlock = getDefaultMidiBlock(theme, {
      y: blockLayout.y + blockLayout.h - 1,
      x: blockLayout.x,
      w: blockLayout.w,
      h: blockLayout.h,
    });
    dispatch(addMidiBlockAndLayout(newBlock));
    dispatch(
      openDrawer({
        drawerId: 'BLOCK_SETTINGS',
        drawerData: { blockId: newBlock.midiBlock.id },
        tabValue: 0,
      })
    );
  };

  const deleteBlock = () => {
    dispatch(removeMidiBlockAndLayout(blockId));
  };

  const renderWidget = () => {
    let widget = null;
    let widgetButtons = null;
    if (height && width) {
      Object.keys(widgetModules).forEach((name) => {
        if (block.widget === name) {
          const widgetModule = widgetModules[name];
          let WidgetElement = widgetModule.Component;
          widget = (
            <WidgetElement
              block={block}
              hover={hover}
              containerHeight={height}
              containerWidth={width}
              widgetSettings={block.widgetSettings}
            />
          );
          if (widgetModule.ButtonsComponent) {
            let WidgetButtonsElement = widgetModule.ButtonsComponent;
            widgetButtons = <WidgetButtonsElement block={block} />;
          }
        }
      });
    }
    return { widget, widgetButtons };
  };

  const closeDeleteMenu = () => {
    setDeleteAnchorEl(null);
  };

  const blockButtonsVisible =
    hover ||
    (joyrideTour.tour === 'GET_STARTED' &&
      blockIndex === 0 &&
      joyrideTour.stepIndex === 1);

  return (
    <ThemeProvider theme={theme}>
      <Box
        className={`midi-block-${blockIndex}`}
        ref={ref}
        onMouseEnter={handleHoverEvent(true)}
        onMouseLeave={handleHoverEvent(false)}
        key={block.id}
        sx={{
          bgcolor: 'background.paper',
          height: '100%',
          overflow: 'hidden',
          borderRadius: '5px',
        }}
      >
        <Box sx={{ height: '100%', width: '100%' }}>
          {renderWidget().widget}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row-reverse',
            flexWrap: 'wrap',
            alignItems: 'center',
            position: 'absolute',
            top: 0,
            right: 0,
            pt: 1,
            pb: 1,
            ...(hover && {
              overflow: 'auto',
              maxHeight: '100%',
              maxWidth: '100%',
            }),
          }}
        >
          {isTouchDevice && (
            <Button
              color="primary"
              variant="contained"
              aria-label="expand options"
              onClick={() => setHover(!hover)}
              className={msClasses.widgetSideButton}
              sx={{
                background: 'transparent',
                border: `2px solid ${theme.palette.primary.main}`,
                '&:hover': { background: 'transparent' },
              }}
            >
              {hover ? (
                <ChevronRightIcon fontSize="small" color="primary" />
              ) : (
                <ChevronLeftIcon fontSize="small" color="primary" />
              )}
            </Button>
          )}
          {blockButtonsVisible && (
            <>
              <Tooltip arrow title="Drag Handle" placement="top">
                <Button
                  color="primary"
                  variant="contained"
                  sx={{
                    cursor: 'grab',
                  }}
                  aria-label="drag-handle"
                  className={`blockDragHandle ${msClasses.widgetSideButton}`}
                >
                  <DragHandleOutlinedIcon />
                </Button>
              </Tooltip>
              <Tooltip arrow title="Block Settings" placement="top">
                <Button
                  className={`block-settings-btn-${blockIndex} ${msClasses.widgetSideButton}`}
                  color="primary"
                  variant="contained"
                  onClick={openBlockSettings}
                  aria-label="settings"
                  size="small"
                >
                  <SettingsOutlinedIcon />
                </Button>
              </Tooltip>
              <Tooltip arrow title="Add Block Below" placement="top">
                <Button
                  color="primary"
                  variant="contained"
                  className={msClasses.widgetSideButton}
                  onClick={addNewBlock}
                  aria-label="add block below"
                >
                  <AddIcon />
                </Button>
              </Tooltip>
              {!deleteDisabled && (
                <>
                  <Tooltip arrow title="Delete Block" placement="top">
                    <Button
                      variant="contained"
                      className={msClasses.widgetSideButton}
                      onClick={(e: MouseEvent<HTMLButtonElement>) => {
                        setDeleteAnchorEl(e.currentTarget);
                      }}
                      id="delete-block"
                      aria-controls={openDeleteMenu ? 'basic-menu' : undefined}
                      aria-expanded={openDeleteMenu ? 'true' : undefined}
                      aria-label="delete block"
                      aria-haspopup="true"
                    >
                      <DeleteIcon />
                    </Button>
                  </Tooltip>
                  <Menu
                    id="delete-block-menu"
                    anchorEl={deleteAnchorEl}
                    open={openDeleteMenu}
                    onClose={closeDeleteMenu}
                    MenuListProps={{
                      'aria-labelledby': 'delete-block',
                    }}
                  >
                    <MenuItem onClick={deleteBlock}>Confirm Delete</MenuItem>
                    <MenuItem onClick={closeDeleteMenu}>Cancel</MenuItem>
                  </Menu>
                </>
              )}
              <Box>{renderWidget().widgetButtons}</Box>
            </>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default MidiBlock;
