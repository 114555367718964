import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from '../store';

interface OSMDState {
  osmdCursorNotes: number[];
}
const initialState: OSMDState = {
  osmdCursorNotes: [],
};

const osmdStateSlice = createSlice({
  name: 'osmdState',
  initialState,
  reducers: {
    updateOSMDState(state, action: PayloadAction<Partial<OSMDState>>) {
      return { ...state, ...action.payload };
    },
  },
});

export const { updateOSMDState } = osmdStateSlice.actions;

export const selectOSMDCursorNotes = createSelector(
  [(state: RootState) => state.osmdState.osmdCursorNotes],
  (osmdCursorNotes) => osmdCursorNotes
);

const checkCursorNote = (
  state: RootState,
  noteNum: number,
  disableCheck = false
) => {
  if (disableCheck) return false;
  return state.osmdState.osmdCursorNotes.includes(noteNum);
};
export const selectIsCursorNote = createSelector(
  [checkCursorNote],
  (isCursorNote) => isCursorNote
);

export default osmdStateSlice.reducer;
