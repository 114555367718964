import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Button, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GetTemplateQuery, UpdateTemplateMutation } from '../../API';
import { updateTemplate } from '../../graphql/mutations';
import { getTemplate } from '../../graphql/queries';
import {
  mapGetTemplateQuery,
  mapUpdateTemplateMutation,
} from '../../models/template';
import { SandboxUrlParams } from '../../pages/Sandbox';
import useAuth, { callGraphQL } from '../../utils/amplifyUtils';
import {
  useNotificationDispatch,
  useTemplateConstructor,
} from '../../utils/hooks';

function SaveTemplateButton() {
  const { currentUser } = useAuth();
  const { templateId } = useParams<SandboxUrlParams>();
  const templateConstructor = useTemplateConstructor();
  const [successTooltip, setSuccessTooltip] = useState(false);
  const notificationDispatch = useNotificationDispatch();
  const [currentTemplate, setCurrentTemplate] = useState<BlockTemplate>();
  let user: any = currentUser;

  useEffect(() => {
    async function getCurrentTemplate() {
      if (templateId) {
        try {
          const templateData = await callGraphQL<GetTemplateQuery>(
            getTemplate,
            { id: templateId },
            GRAPHQL_AUTH_MODE.API_KEY
          );
          const template = mapGetTemplateQuery(templateData);
          if (template) {
            setCurrentTemplate(template);
          } else {
            throw new Error(
              `Unable to retrieve template from response: ${templateData}`
            );
          }
        } catch (error) {
          console.log('error: ', error);
        }
      }
    }
    getCurrentTemplate();
  }, [templateId]);

  const handleTemplateOverwrite = async () => {
    if (currentTemplate) {
      const updateResp = await callGraphQL<UpdateTemplateMutation>(
        updateTemplate,
        {
          input: {
            id: currentTemplate.id,
            template: JSON.stringify(
              templateConstructor(currentTemplate.id, currentTemplate.name)
            ),
          },
        }
      );
      const updatedTemplate = mapUpdateTemplateMutation(updateResp);
      if (updatedTemplate) {
        setSuccessTooltip(true);
        setTimeout(() => {
          setSuccessTooltip(false);
        }, 3000);
      } else {
        notificationDispatch(
          'An error occurred while updating your template. Please try refreshing the page or contact support for help.',
          'error',
          `Update template mutation failed! ${JSON.stringify(updateResp)}`,
          8000
        );
      }
    }
  };

  if (!templateId || user?.username !== currentTemplate?.owner) return null;
  return (
    <Tooltip
      arrow
      title={successTooltip ? 'Saved!' : 'Save Current Template'}
      placement="top"
    >
      <Button
        sx={{ minWidth: 0 }}
        onClick={handleTemplateOverwrite}
        color="primary"
      >
        <SaveAltIcon />
      </Button>
    </Tooltip>
  );
}

export default SaveTemplateButton;
