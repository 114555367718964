import { Checkbox, Grid, Slider, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { debounce } from 'lodash';
import { useMemo, useState } from 'react';
import { Utilities } from 'webmidi';
import { useAppDispatch } from '../../../redux/store';
import { updateOneMidiBlock } from '../../../redux/slices/midiBlockSlice';
import { useBlockSettingStyles } from '../../../styles/styleHooks';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

interface PianoSettingsProps {
  block: MidiBlockT;
}
function PianoSettings({ block }: PianoSettingsProps) {
  const classes = useBlockSettingStyles();
  const dispatch = useAppDispatch();
  const [pianoSettings, setPianoSettings] = useState(block.pianoSettings);
  const {
    accidental: startNoteAccidental,
    name: startNoteName,
    octave: startNoteOctave,
  } = Utilities.getNoteDetails(pianoSettings.startNote) as any;

  const updateSettings = (updatedPianoSettings: PianoSettingsT) => {
    setPianoSettings(updatedPianoSettings);
    dispatch(
      updateOneMidiBlock({
        id: block.id,
        changes: {
          pianoSettings: updatedPianoSettings,
        },
      })
    );
  };

  const debouncedStoreUpdate = useMemo(
    () =>
      debounce((updatedPianoSettings: PianoSettingsT) => {
        dispatch(
          updateOneMidiBlock({
            id: block.id,
            changes: {
              pianoSettings: updatedPianoSettings,
            },
          })
        );
      }, 500),
    [dispatch, block.id]
  );

  const handleSliderChange =
    (setting: keyof PianoSettingsT) =>
    (event: Event, newValue: number | number[]) => {
      const updatedPianoSettings = { ...pianoSettings, [setting]: newValue };
      setPianoSettings(updatedPianoSettings);
      debouncedStoreUpdate(updatedPianoSettings);
    };

  const handleCheckboxClick = (setting: keyof PianoSettingsT) => () => {
    updateSettings({
      ...pianoSettings,
      [setting]: !pianoSettings[setting],
    });
  };

  return (
    <>
      <Grid item xs={12}>
        <Box>
          <Typography variant="body1" id="startNote" gutterBottom>
            Start Note:
            <Typography color="secondary" component="span" fontWeight={500}>
              {' '}
              {`${startNoteName}${
                startNoteAccidental ? startNoteAccidental : ''
              }${startNoteOctave}`}
            </Typography>
          </Typography>
          <Box sx={{ mr: 3 }}>
            <Slider
              value={pianoSettings.startNote}
              onChange={handleSliderChange('startNote')}
              aria-labelledby="startNote"
              min={0}
              max={127}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <Typography variant="body1" id="keyWidth" gutterBottom>
            Key Width: {` ${pianoSettings.keyWidth * 1000}`}
          </Typography>
          <Box sx={{ mr: 3 }}>
            <Slider
              value={pianoSettings.keyWidth}
              onChange={handleSliderChange('keyWidth')}
              aria-labelledby="keyWidth"
              step={0.001}
              min={0.015}
              max={0.1}
            />
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box
          onClick={handleCheckboxClick('showPianoRoll')}
          sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
          className={classes.checkbox}
        >
          <Checkbox checked={pianoSettings.showPianoRoll} />
          <Typography variant="body1">Show Piano Roll</Typography>
        </Box>
      </Grid>
      {pianoSettings.showPianoRoll && (
        <>
          <Grid item xs={12}>
            <Box
              onClick={handleCheckboxClick('showSustainPianoRoll')}
              sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
              className={classes.checkbox}
            >
              <Checkbox checked={pianoSettings.showSustainPianoRoll} />
              <Typography variant="body1">Show Sustain Bars</Typography>
              <Tooltip
                arrow
                title="Displays which notes are being sustained in the piano roll."
                placement="top"
              >
                <HelpOutlineIcon color="secondary" sx={{ ml: 2 }} />
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Typography variant="body1" id="keyBedHeight" gutterBottom>
                Key Bed Height:{' '}
                {` ${Math.floor(pianoSettings.keyBedHeight * 100)}%`}
              </Typography>
              <Box sx={{ mr: 3 }}>
                <Slider
                  value={pianoSettings.keyBedHeight}
                  onChange={handleSliderChange('keyBedHeight')}
                  aria-labelledby="keyBedHeight"
                  step={0.01}
                  min={0.1}
                  max={1}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Typography variant="body1" id="pianoRollSpeed" gutterBottom>
                Piano Roll Speed:{' '}
                {` ${pianoSettings.pianoRollSpeed.toFixed(1)}x`}
              </Typography>
              <Box sx={{ mr: 3 }}>
                <Slider
                  value={pianoSettings.pianoRollSpeed}
                  onChange={handleSliderChange('pianoRollSpeed')}
                  aria-labelledby="pianoRollSpeed"
                  step={0.01}
                  min={0}
                  max={5}
                />
              </Box>
            </Box>
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Box
          onClick={handleCheckboxClick('highlightOSMDCursorNotes')}
          sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
          className={classes.checkbox}
        >
          <Checkbox checked={pianoSettings.highlightOSMDCursorNotes} />
          <Typography variant="body1">Highlight Cursor Notes</Typography>
          <Tooltip
            arrow
            title="When using the Sheet Music widget, the notes underneath the cursor will be highlighted in the Piano widget."
            placement="top"
          >
            <HelpOutlineIcon color="secondary" sx={{ ml: 2 }} />
          </Tooltip>
        </Box>
      </Grid>
    </>
  );
}

export default PianoSettings;
