import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { Layout } from 'react-grid-layout';
import { RootState } from '../store';
import {
  addMidiBlockAndLayout,
  removeMidiBlockAndLayout,
} from './midiBlockSlice';

const responsiveLayoutAdapter = createEntityAdapter<ResponsiveLayout>({
  selectId: (responsiveLayout) => responsiveLayout.blockId,
});

const initialState = responsiveLayoutAdapter.getInitialState();

const responsiveLayoutSlice = createSlice({
  name: 'responsiveLayout',
  initialState,
  reducers: {
    addResponsiveLayouts: responsiveLayoutAdapter.addOne,
    upsertManyResponsiveLayouts: responsiveLayoutAdapter.upsertMany,
    updateManyResponsiveLayouts: responsiveLayoutAdapter.updateMany,
    setAllResponsiveLayouts: responsiveLayoutAdapter.setAll,
  },
  extraReducers: (builder) => {
    builder
      .addCase(addMidiBlockAndLayout, (state, action) => {
        responsiveLayoutAdapter.addOne(state, {
          blockId: action.payload.blockLayout.i,
          lg: action.payload.blockLayout,
        });
      })
      .addCase(removeMidiBlockAndLayout, responsiveLayoutAdapter.removeOne);
  },
});

export const {
  addResponsiveLayouts,
  upsertManyResponsiveLayouts,
  updateManyResponsiveLayouts,
  setAllResponsiveLayouts,
} = responsiveLayoutSlice.actions;

export const { selectAll: selectAllResponsiveLayouts } =
  responsiveLayoutAdapter.getSelectors<RootState>(
    (state) => state.responsiveLayout
  );

export default responsiveLayoutSlice.reducer;
