/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTemplate = /* GraphQL */ `
  mutation CreateTemplate(
    $input: CreateTemplateInput!
    $condition: ModelTemplateConditionInput
  ) {
    createTemplate(input: $input, condition: $condition) {
      id
      template
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateTemplate = /* GraphQL */ `
  mutation UpdateTemplate(
    $input: UpdateTemplateInput!
    $condition: ModelTemplateConditionInput
  ) {
    updateTemplate(input: $input, condition: $condition) {
      id
      template
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteTemplate = /* GraphQL */ `
  mutation DeleteTemplate(
    $input: DeleteTemplateInput!
    $condition: ModelTemplateConditionInput
  ) {
    deleteTemplate(input: $input, condition: $condition) {
      id
      template
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createCustomTheme = /* GraphQL */ `
  mutation CreateCustomTheme(
    $input: CreateCustomThemeInput!
    $condition: ModelCustomThemeConditionInput
  ) {
    createCustomTheme(input: $input, condition: $condition) {
      id
      customTheme
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCustomTheme = /* GraphQL */ `
  mutation UpdateCustomTheme(
    $input: UpdateCustomThemeInput!
    $condition: ModelCustomThemeConditionInput
  ) {
    updateCustomTheme(input: $input, condition: $condition) {
      id
      customTheme
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCustomTheme = /* GraphQL */ `
  mutation DeleteCustomTheme(
    $input: DeleteCustomThemeInput!
    $condition: ModelCustomThemeConditionInput
  ) {
    deleteCustomTheme(input: $input, condition: $condition) {
      id
      customTheme
      createdAt
      updatedAt
      owner
    }
  }
`;
