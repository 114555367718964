import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import TabletAndroidOutlinedIcon from '@mui/icons-material/TabletAndroidOutlined';
import {
  Box,
  Grid,
  PaletteMode,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  selectGlobalSettings,
  updateGlobalSetting,
} from '../../../redux/slices/globalSettingsSlice';
import {
  selectDefaultInputChannel,
  setDefaultInputChannel,
} from '../../../redux/slices/midiBlockSlice';
import { useAppDispatch, useTypedSelector } from '../../../redux/store';
import KeySettings from './KeySettings';
import SelectMidiInputChannel from './SelectMidiInputChannel';
import ThemeSelect from './ThemeSelect';

export default function GlobalSettingsTab() {
  const dispatch = useAppDispatch();
  const muiTheme = useTheme();
  const { defaultInputId, defaultChannelId } = useTypedSelector(
    selectDefaultInputChannel
  );
  const globalSettings = useTypedSelector(selectGlobalSettings);

  const handleSettingChange = (settings: Partial<GlobalSettings>) => {
    dispatch(updateGlobalSetting(settings));
  };

  const handleBreakpointChange = (
    event: React.MouseEvent<HTMLElement>,
    responsiveBreakpoint: string | null
  ) => {
    handleSettingChange({
      responsiveBreakpoint: responsiveBreakpoint as GlobalBreakpoint,
    });
  };

  return (
    <Grid sx={{ pl: 3, pr: 3, mb: 2 }} container rowSpacing={4}>
      <Grid item xs={12}>
        <ThemeSelect
          themeId={globalSettings.themeMode}
          themeName={muiTheme.custom.name}
          label={'Global Theme'}
          handleThemeChange={(themeId: string) => {
            handleSettingChange({ themeMode: themeId as PaletteMode });
          }}
          handleThemeDelete={(themeId: string) => {
            if (themeId === globalSettings.themeMode) {
              handleSettingChange({ themeMode: 'dark' });
            }
          }}
          showDefaultOption={false}
        />
      </Grid>
      <SelectMidiInputChannel
        source="global"
        handleInputChannelChange={(newInputId: string, newChannelId: string) =>
          dispatch(
            setDefaultInputChannel({
              defaultInputId: newInputId,
              defaultChannelId: newChannelId,
            })
          )
        }
        inputId={defaultInputId}
        channelId={defaultChannelId}
      />
      <Grid item xs={12}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography sx={{ mr: 2 }}>Layout:</Typography>

          <ToggleButtonGroup
            size="small"
            color="primary"
            value={globalSettings.responsiveBreakpoint}
            exclusive
            onChange={handleBreakpointChange}
            aria-label="Layout"
          >
            <ToggleButton
              sx={{ borderRadius: '5px 0 0 5px' }}
              value="auto"
              aria-label="auto"
            >
              Auto
            </ToggleButton>
            <ToggleButton value="lg" aria-label="desktop">
              <DesktopWindowsOutlinedIcon />
            </ToggleButton>
            <ToggleButton value="md" aria-label="tablet">
              <TabletAndroidOutlinedIcon />
            </ToggleButton>
            <ToggleButton
              sx={{ borderRadius: '0 5px 5px 0' }}
              value="sm"
              aria-label="mobile"
            >
              <PhoneAndroidOutlinedIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <KeySettings />
      </Grid>
    </Grid>
  );
}
