import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import GridLayout, { Layout } from 'react-grid-layout';

import { useResizeDetector } from 'react-resize-detector';
import { selectGlobalSettings } from '../../redux/slices/globalSettingsSlice';
import {
  selectAllResponsiveLayouts,
  updateManyResponsiveLayouts,
} from '../../redux/slices/responsiveLayoutSlice';
import { useAppDispatch, useTypedSelector } from '../../redux/store';
import { getBreakpoint, getResponsiveBlockLayout } from '../../utils/utils';
import MidiBlock from './MidiBlock';

const BlockLayout = () => {
  const { width, ref } = useResizeDetector();
  const theme = useTheme();
  const responsiveLayouts = useTypedSelector(selectAllResponsiveLayouts);
  const dispatch = useAppDispatch();
  const globalSettings = useTypedSelector(selectGlobalSettings);
  let breakpoint: ResponsiveBreakpoint = window.innerWidth
    ? getBreakpoint(window.innerWidth)
    : 'lg';
  if (globalSettings.responsiveBreakpoint !== 'auto') {
    breakpoint = globalSettings.responsiveBreakpoint;
  }

  const onLayoutChange = (updatedLayout: Layout[]) => {
    let updatePayload: { id: string; changes: Partial<ResponsiveLayout> }[] =
      [];
    updatedLayout.forEach((layout) => {
      updatePayload.push({
        id: layout.i,
        changes: {
          [breakpoint]: layout,
        },
      });
    });
    dispatch(updateManyResponsiveLayouts(updatePayload));
  };

  return (
    <Box ref={ref}>
      <GridLayout
        key={`${breakpoint}`}
        width={width ? width : 0}
        className="layout"
        draggableHandle=".blockDragHandle"
        margin={[theme.custom.spacingUnit * 2, theme.custom.spacingUnit * 2]}
        cols={12}
        rowHeight={theme.custom.spacingUnit * 2}
        onLayoutChange={onLayoutChange}
        resizeHandle={
          <Box
            sx={{
              position: 'absolute',
              bottom: 2,
              right: 2,
              width: theme.spacing(5),
              height: theme.spacing(5),
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'grab',
            }}
          >
            <Box
              sx={{
                width: theme.spacing(3),
                height: theme.spacing(3),
                borderRight: `4px solid ${theme.palette.primary.main}`,
                borderBottom: `4px solid ${theme.palette.primary.main}`,
                zIndex: 0,
              }}
            ></Box>
          </Box>
        }
      >
        {responsiveLayouts.map((responsiveLayout, i) => {
          const layout = getResponsiveBlockLayout(responsiveLayout, breakpoint);
          return (
            <Box
              key={`${responsiveLayout.blockId}`}
              data-grid={{
                ...layout,
                ...blockLayoutTemplate,
              }}
            >
              <MidiBlock
                blockIndex={i}
                deleteDisabled={responsiveLayouts.length === 1}
                blockLayout={layout}
              />
            </Box>
          );
        })}
      </GridLayout>
    </Box>
  );
};

const blockLayoutTemplate: Partial<Layout> = {
  minH: 5,
};

export default BlockLayout;
