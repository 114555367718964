import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HomeIcon from '@mui/icons-material/Home';

import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import TourIcon from '@mui/icons-material/Tour';
import { Button, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  drawerWidth,
  openDrawer,
  selectDrawerContainer,
} from '../../redux/slices/drawerContainerSlice';
import { updateJoyrideTour } from '../../redux/slices/joyrideTourSlice';
import { useAppDispatch, useTypedSelector } from '../../redux/store';
import useAuth from '../../utils/amplifyUtils';
import FullscreenButton from '../utilComponents/FullscreenButton';
import SaveTemplateButton from '../utilComponents/SaveTemplateButton';

interface DrawerFooterProps {
  zIndex?: number;
}
export const DrawerFooter = (
  props: React.PropsWithChildren<DrawerFooterProps>
) => {
  const theme = useTheme();
  const { footerHeight } = useTypedSelector((state) =>
    selectDrawerContainer(state)
  );

  const { zIndex } = props;
  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        right: 0,
        width: drawerWidth,
        display: 'flex',
        alignItems: 'center',
        height: footerHeight,
        overflow: 'hidden',
        borderTop: `1px solid ${theme.palette.divider}`,
        borderLeft: `1px solid ${theme.palette.divider}`,
        background: theme.palette.background.paper,
        ...(zIndex !== undefined && { zIndex }),
      }}
    >
      {props.children}
    </Box>
  );
};

export const DefaultDrawerFooter = () => {
  const dispatch = useAppDispatch();
  const { footerHeight } = useTypedSelector((state) =>
    selectDrawerContainer(state)
  );
  const { currentUser, signOut } = useAuth();
  const startTour = () => {
    dispatch(
      openDrawer({
        drawerId: 'BLOCK_SETTINGS',
        drawerData: { blockId: '' },
        tabValue: 1,
      })
    );
    dispatch(
      updateJoyrideTour({
        tour: 'GET_STARTED',
        stepIndex: 0,
      })
    );
  };
  return (
    <DrawerFooter zIndex={-1}>
      <Box sx={{ flexGrow: 1 }}>
        <a
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: 'none' }}
          href="https://midisandbox.com/"
        >
          <Tooltip arrow title="Home" placement="top">
            <Button sx={{ minWidth: 0 }} color="primary">
              <HomeIcon />
            </Button>
          </Tooltip>
        </a>
        <SaveTemplateButton />
        <a
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: 'none' }}
          href="https://midisandbox.com/faq"
        >
          <Tooltip arrow title="FAQ" placement="top">
            <Button sx={{ minWidth: 0 }} color="primary">
              <HelpOutlineIcon />
            </Button>
          </Tooltip>
        </a>
        <Tooltip arrow title="Tour" placement="top">
          <Button sx={{ minWidth: 0 }} onClick={startTour} color="primary">
            <TourIcon />
          </Button>
        </Tooltip>
        {currentUser ? (
          <Tooltip arrow title="Logout" placement="top">
            <Button sx={{ minWidth: 0 }} onClick={signOut} color="primary">
              <LogoutIcon />
            </Button>
          </Tooltip>
        ) : (
          <Link style={{ textDecoration: 'none' }} to="/login">
            <Tooltip arrow title="Login" placement="top">
              <Button sx={{ minWidth: 0 }} color="primary">
                <LoginIcon />
              </Button>
            </Tooltip>
          </Link>
        )}
      </Box>
      <FullscreenButton width={footerHeight} height={footerHeight} />
    </DrawerFooter>
  );
};
