import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';

import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { DeleteCustomThemeMutation } from '../../../API';
import { deleteCustomTheme } from '../../../graphql/mutations';
import {
  removeCustomTheme,
  selectAllCustomThemes,
} from '../../../redux/slices/customThemeSlice';
import { useAppDispatch, useTypedSelector } from '../../../redux/store';
import {
  blockSettingMenuProps,
  useBlockSettingStyles,
} from '../../../styles/styleHooks';
import { callGraphQL } from '../../../utils/amplifyUtils';
import { useNotificationDispatch } from '../../../utils/hooks';
import { openModal } from '../../utilComponents/modalContainer/modalContainerSlice';

interface ThemeSelectOptions {
  themeId: string;
  themeName: string;
  label: string;
  handleThemeChange: (themeId: string) => void;
  handleThemeDelete: (themeId: string) => void;
  showDefaultOption?: boolean;
}
export default function ThemeSelect({
  themeId,
  themeName,
  label,
  handleThemeChange,
  handleThemeDelete,
  showDefaultOption,
}: ThemeSelectOptions) {
  const customThemes = useTypedSelector(selectAllCustomThemes);
  const notificationDispatch = useNotificationDispatch();
  const dispatch = useAppDispatch();
  const classes = useBlockSettingStyles();
  const muiTheme = useTheme();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCustomThemeDelete = async (themeId: string) => {
    const deleteResp = await callGraphQL<DeleteCustomThemeMutation>(
      deleteCustomTheme,
      { input: { id: themeId } }
    );
    if (deleteResp.data?.deleteCustomTheme?.id) {
      handleThemeDelete(deleteResp.data.deleteCustomTheme.id);
      dispatch(removeCustomTheme(deleteResp.data.deleteCustomTheme.id));
    } else {
      notificationDispatch(
        'An error occurred while deleting your theme. Please try refreshing the page or contact support for help.',
        'error',
        `Delete theme mutation failed! ${JSON.stringify(deleteResp)}`,
        8000
      );
    }
  };

  const handleChange = (e: SelectChangeEvent<string>) => {
    let value = e.target.value;
    if (value !== 'new-theme') {
      handleThemeChange(value);
    }
  };

  return (
    <FormControl className={classes.select} size="small" fullWidth>
      <InputLabel id="block-themeMode-label">{label}</InputLabel>
      <Select
        labelId="block-themeMode-label"
        value={themeId}
        onOpen={handleOpen}
        onClose={handleClose}
        open={open}
        renderValue={(val) => themeName}
        label={label}
        onChange={handleChange}
        MenuProps={blockSettingMenuProps}
      >
        <MenuItem
          value="new-theme"
          onClick={() => {
            dispatch(openModal({ modalId: 'THEME_SETTINGS', modalData: null }));
          }}
          sx={{ color: muiTheme.palette.primary.main }}
        >
          <CreateIcon sx={{ mr: 2 }} fontSize="small" />
          Create New Theme
        </MenuItem>
        {showDefaultOption && <MenuItem value={'default'}>Default</MenuItem>}
        <MenuItem value={'dark'}>Dark</MenuItem>
        <MenuItem value={'light'}>Light</MenuItem>
        {customThemes.map((x) => (
          <Box key={x.id} sx={{ display: 'flex' }}>
            <MenuItem
              sx={{ flexGrow: 1 }}
              selected={x.id === themeId}
              onClick={(e) => {
                handleThemeChange(x.id);
                handleClose();
              }}
              value={x.id}
            >
              <Box sx={{ flexGrow: 1 }}>{x.name}</Box>
            </MenuItem>
            <Box className="theme-icon-buttons">
              <IconButton
                className="theme-delete-icon"
                color="error"
                aria-label="delete file"
                component="span"
                size="small"
                onClick={() => handleCustomThemeDelete(x.id)}
                sx={{
                  position: 'relative',
                  right: `-${0}px`,
                  borderRadius: 0,
                  height: '100%',
                  width: `${45}px`,
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>
        ))}
        {customThemes.map((x) => (
          <MenuItem
            sx={{ height: '0px', p: 0 }}
            value={x.id}
            key={`hidden-${x.id}`}
          ></MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
