import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { debounce } from 'lodash';
import { useEffect, useMemo, useState, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CreateCustomThemeMutation } from '../../../API';
import { createCustomTheme } from '../../../graphql/mutations';
import { mapCreateCustomThemeMutation } from '../../../models/customTheme';
import { addCustomTheme } from '../../../redux/slices/customThemeSlice';
import { updateGlobalSetting } from '../../../redux/slices/globalSettingsSlice';
import { useAppDispatch } from '../../../redux/store';
import {
  blockSettingMenuProps,
  useBlockSettingStyles,
} from '../../../styles/styleHooks';
import { callGraphQL } from '../../../utils/amplifyUtils';
import { useNotificationDispatch } from '../../../utils/hooks';
import ColorPicker from '../ColorPicker';

export interface ThemeSettingsModalProps {
  handleClose: Function;
}
export default function ThemeSettingsModal({
  handleClose,
}: ThemeSettingsModalProps) {
  const muiTheme = useTheme();
  const notificationDispatch = useNotificationDispatch();
  const dispatch = useAppDispatch();
  const classes = useBlockSettingStyles();
  const initThemeSettings = useRef<CustomTheme>({
    id: uuidv4(),
    createdAt: '',
    mode: muiTheme.palette.mode,
    name: '',
    primary: muiTheme.palette.primary.main,
    secondary: muiTheme.palette.secondary.main,
    error: muiTheme.palette.error.main,
    backgroundPaper: muiTheme.palette.background.paper,
    backgroundDefault: muiTheme.palette.background.default,
    text: muiTheme.palette.text.primary,
    divider: muiTheme.palette.divider,
    note0: muiTheme.custom.note0,
    note1: muiTheme.custom.note1,
    note2: muiTheme.custom.note2,
    note3: muiTheme.custom.note3,
    note4: muiTheme.custom.note4,
    note5: muiTheme.custom.note5,
    note6: muiTheme.custom.note6,
    note7: muiTheme.custom.note7,
    note8: muiTheme.custom.note8,
    note9: muiTheme.custom.note9,
    note10: muiTheme.custom.note10,
    note11: muiTheme.custom.note11,
  });
  const [themeSettings, setThemeSettings] = useState(initThemeSettings.current);
  const [prevThemeId] = useState(muiTheme.custom.id);

  useEffect(() => {
    dispatch(
      updateGlobalSetting({
        tempEditTheme: initThemeSettings.current,
      })
    );

    return () => {
      dispatch(
        updateGlobalSetting({ themeMode: prevThemeId, tempEditTheme: null })
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const onSave = () => {
    handleCustomThemeCreate();
    handleClose();
  };

  const updateSettings = (setting: keyof CustomTheme, val: string) => {
    let newSettings = { ...themeSettings, [setting]: val };
    setThemeSettings(newSettings);
    debouncedGlobalSettingUpdate(newSettings);
  };

  const debouncedGlobalSettingUpdate = useMemo(
    () =>
      debounce((newSettings: CustomTheme) => {
        dispatch(
          updateGlobalSetting({
            tempEditTheme: newSettings,
          })
        );
      }, 500),
    [dispatch]
  );

  const handleColorChange = (setting: keyof CustomTheme) => (color: string) => {
    updateSettings(setting, color);
  };

  const handleCustomThemeCreate = async () => {
    if (themeSettings?.name) {
      try {
        // id will be set to dynamoDB id once posted
        const createResp = await callGraphQL<CreateCustomThemeMutation>(
          createCustomTheme,
          {
            input: {
              customTheme: JSON.stringify(themeSettings),
            },
          }
        );
        const theme = mapCreateCustomThemeMutation(createResp);
        if (theme) {
          dispatch(addCustomTheme(theme));
          dispatch(
            updateGlobalSetting({
              themeMode: theme.id,
            })
          );
        } else {
          throw new Error(`unexpected response: ${JSON.stringify(createResp)}`);
        }
      } catch (error) {
        notificationDispatch(
          'An error occurred while creating your theme',
          'error',
          error
        );
      }
      handleClose();
    }
  };

  const handleSelectChange =
    (setting: keyof CustomTheme) => (e: SelectChangeEvent) => {
      const {
        target: { value },
      } = e;
      updateSettings(setting, value);
    };

  if (themeSettings === null) return null;

  return (
    <Box>
      <Typography
        sx={modalStyles.modalHeader}
        id="transition-modal-title"
        variant="h6"
        component="h2"
      >
        Theme Settings
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ mb: 2 }}>
            <TextField
              autoFocus
              // size="small"
              placeholder="Name (required)"
              value={themeSettings.name}
              onChange={(e) => updateSettings('name', e.target.value)}
              sx={{ width: '100%', height: '100%', pl: 1, pr: 1 }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ mb: 4, display: 'flex', alignItems: 'center' }}>
            <FormControl className={classes.select} size="small" fullWidth>
              <Select
                value={themeSettings.mode}
                onChange={handleSelectChange('mode')}
                MenuProps={blockSettingMenuProps}
              >
                <MenuItem value={'dark'}>Dark</MenuItem>
                <MenuItem value={'light'}>Light</MenuItem>
              </Select>
            </FormControl>
            <Tooltip
              arrow
              title="The mode determines the color of things like secondary text and shading, so select Light if you are using a bright background color and Dark if you are using a dark background color."
              placement="top"
            >
              <HelpOutlineIcon color="secondary" sx={{ ml: 2 }} />
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box sx={modalStyles.colorSetting}>
            <ColorPicker
              label={'Primary'}
              color={themeSettings.primary}
              handleChange={handleColorChange('primary')}
            />
          </Box>
          <Box sx={modalStyles.colorSetting}>
            <ColorPicker
              label={'Secondary'}
              color={themeSettings.secondary}
              handleChange={handleColorChange('secondary')}
            />
          </Box>
          <Box sx={modalStyles.colorSetting}>
            <ColorPicker
              label={'Error'}
              color={themeSettings.error}
              handleChange={handleColorChange('error')}
            />
          </Box>
          <Box sx={modalStyles.colorSetting}>
            <ColorPicker
              label={'Background 1'}
              color={themeSettings.backgroundPaper}
              handleChange={handleColorChange('backgroundPaper')}
            />
          </Box>
          <Box sx={modalStyles.colorSetting}>
            <ColorPicker
              label={'Background 2'}
              color={themeSettings.backgroundDefault}
              handleChange={handleColorChange('backgroundDefault')}
            />
          </Box>
          <Box sx={modalStyles.colorSetting}>
            <ColorPicker
              label={'Text'}
              color={themeSettings.text}
              handleChange={handleColorChange('text')}
            />
          </Box>
          <Box sx={modalStyles.colorSetting}>
            <ColorPicker
              label={'Divider'}
              color={themeSettings.divider}
              handleChange={handleColorChange('divider')}
            />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box sx={modalStyles.colorSetting}>
            <ColorPicker
              label={'C'}
              color={themeSettings.note0}
              handleChange={handleColorChange('note0')}
            />
          </Box>
          <Box sx={modalStyles.colorSetting}>
            <ColorPicker
              label={'C#/Db'}
              color={themeSettings.note1}
              handleChange={handleColorChange('note1')}
            />
          </Box>
          <Box sx={modalStyles.colorSetting}>
            <ColorPicker
              label={'D'}
              color={themeSettings.note2}
              handleChange={handleColorChange('note2')}
            />
          </Box>
          <Box sx={modalStyles.colorSetting}>
            <ColorPicker
              label={'D#/Eb'}
              color={themeSettings.note3}
              handleChange={handleColorChange('note3')}
            />
          </Box>
          <Box sx={modalStyles.colorSetting}>
            <ColorPicker
              label={'E'}
              color={themeSettings.note4}
              handleChange={handleColorChange('note4')}
            />
          </Box>
          <Box sx={modalStyles.colorSetting}>
            <ColorPicker
              label={'F'}
              color={themeSettings.note5}
              handleChange={handleColorChange('note5')}
            />
          </Box>
          <Box sx={modalStyles.colorSetting}>
            <ColorPicker
              label={'F#/Gb'}
              color={themeSettings.note6}
              handleChange={handleColorChange('note6')}
            />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box sx={modalStyles.colorSetting}>
            <ColorPicker
              label={'G'}
              color={themeSettings.note7}
              handleChange={handleColorChange('note7')}
            />
          </Box>
          <Box sx={modalStyles.colorSetting}>
            <ColorPicker
              label={'G#/Ab'}
              color={themeSettings.note8}
              handleChange={handleColorChange('note8')}
            />
          </Box>
          <Box sx={modalStyles.colorSetting}>
            <ColorPicker
              label={'A'}
              color={themeSettings.note9}
              handleChange={handleColorChange('note9')}
            />
          </Box>
          <Box sx={modalStyles.colorSetting}>
            <ColorPicker
              label={'A#/Bb'}
              color={themeSettings.note10}
              handleChange={handleColorChange('note10')}
            />
          </Box>
          <Box sx={modalStyles.colorSetting}>
            <ColorPicker
              label={'B'}
              color={themeSettings.note11}
              handleChange={handleColorChange('note11')}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ float: 'right', mt: 2 }}>
            <Button color="inherit" onClick={() => handleClose()}>
              Cancel
            </Button>
            <Tooltip
              arrow
              title={
                !themeSettings.name ? 'Please enter a name before saving.' : ''
              }
              placement="top"
            >
              <Box sx={{ ml: 2, display: 'inline-block' }}>
                <Button
                  disabled={!themeSettings.name}
                  color="primary"
                  onClick={onSave}
                >
                  Save
                </Button>
              </Box>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

const modalStyles = {
  modalHeader: {
    mb: 3,
  },
  colorSetting: {
    mt: 0.5,
    mb: 1,
  },
};
