import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { Storage } from 'aws-amplify';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GetTemplateQuery, ListCustomThemesQuery } from '../API';
import BlockLayout from '../components/blockLayout/BlockLayout';
import DrawerContainer from '../components/drawerContainer/DrawerContainer';
import ModalContainer from '../components/utilComponents/modalContainer/ModalContainer';
import Notifications from '../components/utilComponents/Notifications';
import { getTemplate, listCustomThemes } from '../graphql/queries';
import { mapGetTemplateQuery } from '../models/template';
import {
  initialGlobalSettings,
  setAllGlobalSettings,
} from '../redux/slices/globalSettingsSlice';
import {
  setAllMidiBlocks,
  setDefaultInputChannel,
} from '../redux/slices/midiBlockSlice';
import { useAppDispatch, useTypedSelector } from '../redux/store';
import useAuth, { callGraphQL } from '../utils/amplifyUtils';
import {
  extractSubstring,
  getDefaultMidiBlock,
  getDefaultTemplate,
} from '../utils/utils';

import _ from 'lodash';
import JoyrideTourWrapper from '../components/utilComponents/JoyrideTourWrapper';
import { openDrawer } from '../redux/slices/drawerContainerSlice';
import {
  BucketFolder,
  setAllUploadedFiles,
  storageFolders,
} from '../redux/slices/fileUploadSlice';
import { updateJoyrideTour } from '../redux/slices/joyrideTourSlice';
import { selectDefaultInputChannel } from '../redux/slices/midiBlockSlice';
import {
  selectAllMidiInputs,
  selectInitialInputsLoaded,
} from '../redux/slices/midiListenerSlice';
import { setAllResponsiveLayouts } from '../redux/slices/responsiveLayoutSlice';
import { selectUserActivity } from '../redux/slices/userActivitySlice';
import { useNotificationDispatch } from '../utils/hooks';
import { mapListCustomThemesQuery } from '../models/customTheme';
import { upsertManyCustomTheme } from '../redux/slices/customThemeSlice';

export type SandboxUrlParams = {
  templateId?: string;
};
const Sandbox = () => {
  const muiTheme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const notificationDispatch = useNotificationDispatch();
  const userActivity = useTypedSelector(selectUserActivity);
  const inputs = useTypedSelector(selectAllMidiInputs);
  const { defaultInputId, initialDefaultInputLoaded } = useTypedSelector(
    selectDefaultInputChannel
  );
  const defaultInputFound = inputs.find((x) => x.id === defaultInputId);
  const initialInputsLoaded = useTypedSelector(selectInitialInputsLoaded);
  const { currentUser } = useAuth();
  const { templateId } = useParams<SandboxUrlParams>();
  const [loadedTemplateId, setLoadedTemplateId] = useState('');
  const [templateLoaded, setTemplateLoaded] = useState(false);

  const loadTemplate = useCallback(
    async (templateId: string | undefined) => {
      if (templateId !== loadedTemplateId) {
        let { midiBlocks, responsiveLayouts } = getDefaultTemplate(muiTheme);
        if (templateId) {
          try {
            const templateData = await callGraphQL<GetTemplateQuery>(
              getTemplate,
              { id: templateId },
              GRAPHQL_AUTH_MODE.API_KEY
            );
            const template = mapGetTemplateQuery(templateData);
            if (template) {
              // merge template midi blocks with the default midi block to handle new settings/props
              midiBlocks = template.midiBlocks.map((block) => {
                const mergedBlock = _.merge(
                  getDefaultMidiBlock(muiTheme).midiBlock,
                  block
                );
                return mergedBlock;
              });
              responsiveLayouts =
                template.responsiveLayouts ||
                template.blockLayout?.map((x) => ({ blockId: x.i, lg: x })) ||
                responsiveLayouts;
              dispatch(
                setAllGlobalSettings({
                  ...initialGlobalSettings,
                  ...template.globalSettings,
                })
              );
              dispatch(
                setDefaultInputChannel({
                  defaultInputId: template.defaultInputId,
                  defaultChannelId: template.defaultChannelId,
                })
              );
              setLoadedTemplateId(templateId);
            } else {
              navigate(`/play`);
              throw new Error('Template not found!');
            }
          } catch (err) {
            console.error('err: ', err);
          }
        }
        dispatch(setAllMidiBlocks(midiBlocks));
        dispatch(setAllResponsiveLayouts(responsiveLayouts));

        if (!templateId) {
          // if no template found then open the drawer and select the top default block
          dispatch(
            openDrawer({
              drawerId: 'BLOCK_SETTINGS',
              drawerData: { blockId: midiBlocks[0].id },
              tabValue: 0,
            })
          );
          // we want to dispatch this to trigger initialDefaultInputLoaded=true
          dispatch(
            setDefaultInputChannel({
              defaultInputId: '',
              defaultChannelId: '',
            })
          );
        }
      }
      setTimeout(() => {
        setTemplateLoaded(true);
      }, 1000);
    },
    [muiTheme, dispatch, navigate, loadedTemplateId]
  );

  useEffect(() => {
    loadTemplate(templateId);
  }, [templateId, loadTemplate]);

  // once initial load completes, trigger tour if no default input set
  useEffect(() => {
    if (
      templateLoaded &&
      initialDefaultInputLoaded &&
      initialInputsLoaded &&
      !defaultInputFound?.id &&
      !defaultInputId.includes('/midi/')
    ) {
      dispatch(
        openDrawer({
          drawerId: 'BLOCK_SETTINGS',
          drawerData: { blockId: '' },
          tabValue: 1,
        })
      );
      if (!userActivity.tourComplete) {
        dispatch(
          updateJoyrideTour({
            tour: 'GET_STARTED',
            stepIndex: 0,
          })
        );
      }
    }
  }, [
    templateLoaded,
    defaultInputId,
    initialDefaultInputLoaded,
    defaultInputFound?.id,
    initialInputsLoaded,
    dispatch,
    userActivity.tourComplete,
  ]);

  // load user's uploaded files
  useEffect(() => {
    if (currentUser) {
      Storage.list(currentUser.getUsername())
        .then((result) => {
          const files: UploadedFileT[] = [];
          result.forEach((x) => {
            if (x.key) {
              const folder = extractSubstring(
                x.key,
                `${currentUser.getUsername()}/`,
                '/'
              ) as BucketFolder;
              const keyPathArr = x.key.split('/');
              if (storageFolders.includes(folder)) {
                files.push({
                  key: x.key,
                  lastModified: x.lastModified ? x.lastModified.valueOf() : 0,
                  folder: folder,
                  filename: keyPathArr[keyPathArr.length - 1],
                  size: x.size ? x.size : 0,
                });
              }
            }
          });
          dispatch(setAllUploadedFiles(files));
        })
        .catch((err) => {
          notificationDispatch(
            'An error occurred while loading your files. Please try refreshing the page or contact support for help.',
            'error',
            `Storage.list failed! ${err}`,
            8000
          );
        });
    }
  }, [currentUser, dispatch, notificationDispatch]);

  // load user's custom themes
  useEffect(() => {
    if (currentUser) {
      try {
        callGraphQL<ListCustomThemesQuery>(listCustomThemes).then(
          (themesResp) => {
            let themes = mapListCustomThemesQuery(themesResp).sort(
              (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)
            );
            dispatch(upsertManyCustomTheme(themes));
          }
        );
      } catch (error) {
        notificationDispatch(
          'An error occurred while fetching your templates',
          'error',
          error
        );
      }
    }
  }, [currentUser, dispatch, notificationDispatch]);

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Notifications />
      <ModalContainer />
      <JoyrideTourWrapper />
      <DrawerContainer>
        <BlockLayout />
      </DrawerContainer>
    </Box>
  );
};

export default Sandbox;
