import Box from '@mui/material/Box';
import { useState } from 'react';
import { ChromePicker, ColorResult } from 'react-color';

interface ColorPickerProps {
  label: string;
  color: string;
  handleChange: Function;
}
function ColorPicker({ label, color, handleChange }: ColorPickerProps) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const handleColorPickerClose = () => {
    setDisplayColorPicker(false);
  };

  const handleColorChange = (color: ColorResult) => {
    handleChange(color.hex);
  };

  return (
    <>
      <Box
        sx={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          ':hover': {
            textShadow: '1px 1px 2px grey',
          },
        }}
        onClick={() => setDisplayColorPicker(true)}
      >
        <Box
          sx={{
            display: 'inline-block',
            width: '20px',
            height: '20px',
            mr: 2,
            backgroundColor: color,
            boxShadow: '0px 0px 6px grey',
          }}
        ></Box>
        {label}
      </Box>
      {displayColorPicker ? (
        <Box sx={{ position: 'absolute', zIndex: '2' }}>
          <Box
            sx={{
              position: 'fixed',
              top: '0px',
              right: '0px',
              bottom: '0px',
              left: '0px',
            }}
            onClick={handleColorPickerClose}
          />
          <ChromePicker
            disableAlpha
            color={color}
            onChange={handleColorChange}
          />
        </Box>
      ) : null}
    </>
  );
}

export default ColorPicker;
