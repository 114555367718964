import { GRAPHQL_AUTH_MODE } from '@aws-amplify/auth';
import { createTheme, responsiveFontSizes } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';
import { useEffect, useState, useMemo } from 'react';
import { GetCustomThemeQuery } from '../API';
import { getCustomTheme } from '../graphql/queries';
import { mapGetCustomThemeQuery } from '../models/customTheme';
import { upsertOneCustomTheme } from '../redux/slices/customThemeSlice';
import { selectGlobalSettings } from '../redux/slices/globalSettingsSlice';
import { useAppDispatch, useTypedSelector } from '../redux/store';
import { callGraphQL } from '../utils/amplifyUtils';

export const useBlockSettingStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
    },
    checkbox: {
      marginBottom: theme.spacing(0),
    },
    buttonGroupItem: {
      pl: 0.5,
      pr: 0.5,
      pt: 0.5,
      pb: 0.5,
      border: '0 !important',
      minWidth: '0 !important',
    },
    buttonGroupText: {
      display: 'flex',
      alignItems: 'center',
      margin: '0 -2px',
      pl: 1,
      pr: 1,
    },
  })
);

export const useMsStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButton: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      borderRadius: '50%',
      width: theme.spacing(8),
      height: theme.spacing(8),
      minWidth: 0,
      padding: 0,
    },
    widgetSideButton: {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(1),
      minWidth: '30px',
      borderRadius: '50%',
    },
  })
);

export const blockSettingMenuProps = {
  disableScrollLock: true,
  PaperProps: {
    sx: {
      ml: 1,
    },
  },
};

const spacingUnit = 4;
export const fontFamily = 'Inter';

export const useCustomTheme = (themeId: string) => {
  const [themeObj, setThemeObj] = useState<CustomTheme>(darkMode);
  const dispatch = useAppDispatch();
  const globalSettings = useTypedSelector(selectGlobalSettings);

  useEffect(() => {
    if (globalSettings.tempEditTheme !== null) {
      setThemeObj(globalSettings.tempEditTheme);
    } else if (themeId === 'light') {
      setThemeObj(lightMode);
    } else if (themeId === 'dark') {
      setThemeObj(darkMode);
    } else {
      callGraphQL<GetCustomThemeQuery>(
        getCustomTheme,
        { id: themeId },
        GRAPHQL_AUTH_MODE.API_KEY
      ).then((result) => {
        let themeData = mapGetCustomThemeQuery(result);
        if (themeData) {
          setThemeObj(themeData);
          dispatch(upsertOneCustomTheme(themeData));
        }
      });
    }
  }, [themeId, dispatch, globalSettings.tempEditTheme]);

  return useMemo(
    () =>
      responsiveFontSizes(
        createTheme({
          palette: {
            mode: themeObj.mode,
            primary: {
              main: themeObj.primary,
            },
            secondary: {
              main: themeObj.secondary,
            },
            error: {
              main: themeObj.error,
            },
            background: {
              paper: themeObj.backgroundPaper,
              default: themeObj.backgroundDefault,
            },
            text: {
              primary: themeObj.text,
              // secondary: TODO,
              // disabled: TODO,
              // hint: TODO,
            },
            divider: themeObj.divider,
          },
          custom: {
            id: themeObj.id,
            name: themeObj.name,
            spacingUnit: spacingUnit,
            // TODO: find a overlay color that works for all themes
            loadingOverlayBackground: '#4e4e4e94',
            note0: themeObj.note0,
            note1: themeObj.note1,
            note2: themeObj.note2,
            note3: themeObj.note3,
            note4: themeObj.note4,
            note5: themeObj.note5,
            note6: themeObj.note6,
            note7: themeObj.note7,
            note8: themeObj.note8,
            note9: themeObj.note9,
            note10: themeObj.note10,
            note11: themeObj.note11,
          },
          typography: {
            fontFamily: fontFamily,
            allVariants: {
              color: themeObj.text,
            },
          },
          spacing: spacingUnit,
          shape: {
            borderRadius: 0,
          },
          components: {
            // Name of the component
            MuiCheckbox: {
              styleOverrides: {
                root: {
                  padding: 0,
                  marginRight: spacingUnit * 2,
                },
              },
            },
            MuiTooltip: {
              styleOverrides: {
                tooltip: {
                  fontSize: '1em',
                },
              },
            },
            MuiButton: {
              styleOverrides: {
                root: {
                  textTransform: 'none',
                },
              },
            },
            MuiSelect: {
              styleOverrides: {
                select: {
                  background: 'transparent',
                },
              },
              defaultProps: {
                variant: 'standard',
              },
            },
            MuiTextField: {
              defaultProps: {
                variant: 'standard',
              },
            },
            MuiInputLabel: {
              defaultProps: {
                variant: 'standard',
              },
            },
          },
        })
      ),
    [themeObj]
  );
};

declare module '@mui/material/styles' {
  interface Theme {
    custom: {
      id: string;
      name: string;
      spacingUnit: number;
      loadingOverlayBackground: string;
      note0: string;
      note1: string;
      note2: string;
      note3: string;
      note4: string;
      note5: string;
      note6: string;
      note7: string;
      note8: string;
      note9: string;
      note10: string;
      note11: string;
    };
  }
  interface ThemeOptions {
    custom?: {
      id?: string;
      name?: string;
      spacingUnit?: number;
      loadingOverlayBackground?: string;
      note0?: string;
      note1?: string;
      note2?: string;
      note3?: string;
      note4?: string;
      note5?: string;
      note6?: string;
      note7?: string;
      note8?: string;
      note9?: string;
      note10?: string;
      note11?: string;
    };
  }
}

const lightMode: CustomTheme = {
  id: 'light',
  createdAt: '',
  mode: 'light',
  name: 'Light',
  primary: '#3CA191',
  secondary: '#8744C9',
  error: '#ff6a6a',
  backgroundPaper: '#fff',
  backgroundDefault: '#faf9f8',
  text: '#0C0C0C',
  divider: '#484848',
  note0: '#f5989d',
  note1: '#6dcff6',
  note2: '#fdc689',
  note3: '#8781bd',
  note4: '#c4df9b',
  note5: '#f49bc1',
  note6: '#7accc8',
  note7: '#f9ad81',
  note8: '#7da7d9',
  note9: '#fff79a',
  note10: '#bd8cbf',
  note11: '#83ca9d',
};

const darkMode: CustomTheme = {
  id: 'dark',
  createdAt: '',
  mode: 'dark',
  name: 'Dark',
  primary: '#3CA191',
  secondary: '#9982DA',
  error: '#CB3D2F',
  backgroundPaper: '#141414',
  backgroundDefault: '#070707',
  text: '#ffffff',
  divider: '#2c2c2c',
  note0: '#f5989d',
  note1: '#6dcff6',
  note2: '#fdc689',
  note3: '#8781bd',
  note4: '#c4df9b',
  note5: '#f49bc1',
  note6: '#7accc8',
  note7: '#f9ad81',
  note8: '#7da7d9',
  note9: '#fff79a',
  note10: '#bd8cbf',
  note11: '#83ca9d',
};
