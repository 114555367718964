import {
  Container,
  Sprite,
  Text,
  _ReactPixi,
  useTick,
} from '@inlet/react-pixi';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import useSize from '@react-hook/size';
import * as PIXI from 'pixi.js';
import React, { useMemo, useState, useEffect, useRef } from 'react';
import { useTypedSelector } from '../../../redux/store';
import blackPianoKey from '../../../assets/imgs/blackPianoKey.svg';
import whitePianoKey from '../../../assets/imgs/whitePianoKey.svg';
import whitePianoKeyBordered from '../../../assets/imgs/whitePianoKeyBordered.svg';

import { getNoteOnColors, parseColorToNumber } from '../../../utils/utils';
import {
  selectNotesOnByChannelId,
  selectNotesPressedByChannelId,
} from '../../../redux/slices/midiListenerSlice';
import PixiStageWrapper from '../../utilComponents/PixiStageWrapper';
import { v4 as uuidv4 } from 'uuid';
import PianoSettings from './PianoSettings';
import { selectIsCursorNote } from '../../../redux/slices/osmdStateSlice';
import { fontFamily } from '../../../styles/styleHooks';

const whiteKeyTexture = PIXI.Texture.from(whitePianoKey);
const whiteKeyBorderedTexture = PIXI.Texture.from(whitePianoKeyBordered);
const blackKeyTexture = PIXI.Texture.from(blackPianoKey);

interface PianoProps {
  block: MidiBlockT;
  containerWidth: number;
  containerHeight: number;
}
const Piano = React.memo(
  ({ block, containerWidth, containerHeight }: PianoProps) => {
    const { colorSettings } = block;
    const muiTheme = useTheme();
    const sizeTarget = React.useRef(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [width, height] = useSize(sizeTarget);
    const pianoTextStyle = useMemo(() => {
      let size = 7.5 + block.pianoSettings.keyWidth * 100;

      return new PIXI.TextStyle({
        align: 'center',
        fontFamily: fontFamily,
        fontSize: `${size}px`,
        strokeThickness: 0.5,
        letterSpacing: 2,
      });
    }, [block.pianoSettings.keyWidth]);

    const pianoHeightPercent = block.pianoSettings.showPianoRoll
      ? block.pianoSettings.keyBedHeight
      : 1;
    const keyBedHeight = containerHeight * pianoHeightPercent;
    const keyBedTopY = containerHeight * (1 - pianoHeightPercent);

    // iterate over the note numbers and compute their position/texture for rendering PianoKeySprite
    const renderKeys = () => {
      let output = [];
      let prevWhiteKeyEnd = 0;

      const whiteKeyWidth = block.pianoSettings.keyWidth * width;

      const blackKeyWidth = whiteKeyWidth * 0.74;
      const accidentalOffset1 = 0.45;
      const accidentalOffset2 = 0.259;
      const accidentalOffset3 = 0.333;

      let noteNum = block.pianoSettings.startNote;

      while (prevWhiteKeyEnd <= containerWidth) {
        const chromaticNum = noteNum % 12;
        let isBlackKey = false;
        // set default keySpriteProps (white key)
        let keySpriteProps = {
          texture: whiteKeyTexture,
          width: whiteKeyWidth,
          height: keyBedHeight,
          zIndex: 3,
          x: prevWhiteKeyEnd,
          y: keyBedTopY,
        };
        // if black key
        if ([1, 3, 6, 8, 10].includes(chromaticNum)) {
          isBlackKey = true;
          keySpriteProps.texture = blackKeyTexture;
          keySpriteProps.width = blackKeyWidth;
          keySpriteProps.height = 0.65 * keyBedHeight;
          keySpriteProps.zIndex = 5;
          if ([1, 6].includes(chromaticNum)) {
            keySpriteProps.x =
              prevWhiteKeyEnd - whiteKeyWidth * accidentalOffset1;
          } else if ([3, 10].includes(chromaticNum)) {
            keySpriteProps.x =
              prevWhiteKeyEnd - whiteKeyWidth * accidentalOffset2;
          } else if ([8].includes(chromaticNum)) {
            keySpriteProps.x =
              prevWhiteKeyEnd - whiteKeyWidth * accidentalOffset3;
          }
        } else {
          prevWhiteKeyEnd += keySpriteProps.width + 2;
        }

        // add "C" text at the bottom of the C keys
        if (chromaticNum === 0) {
          const octave = (noteNum - 12) / 12;
          output.push(
            <Text
              key={`note-text-${noteNum}`}
              text={`C${octave}`}
              anchor={0.5}
              x={keySpriteProps.x + 0.5 * keySpriteProps.width}
              y={containerHeight - 16}
              style={pianoTextStyle}
              zIndex={4}
            />
          );
        }

        output.push(
          <PianoKeySprite
            key={`note-${noteNum}`}
            channelId={block.channelId}
            noteNum={noteNum}
            isBlackKey={isBlackKey}
            noteOnColors={getNoteOnColors([noteNum], colorSettings, muiTheme)}
            spriteProps={keySpriteProps}
            showPianoRoll={block.pianoSettings.showPianoRoll}
            showSustainPianoRoll={block.pianoSettings.showSustainPianoRoll}
            pianoRollSpeed={block.pianoSettings.pianoRollSpeed}
            highlightOSMDCursorNotes={
              block.pianoSettings.highlightOSMDCursorNotes
            }
            cursorHighlightColor={muiTheme.palette.primary.main}
          />
        );

        noteNum += 1;
      }

      return output;
    };

    return (
      <Box ref={sizeTarget}>
        <PixiStageWrapper
          width={containerWidth}
          height={containerHeight}
          backgroundColor={parseColorToNumber(
            muiTheme.palette.background.paper
          )}
        >
          <Container sortableChildren>
            {/* the below Sprite is a black background for the keybed */}
            <Sprite
              texture={blackKeyTexture}
              width={containerWidth}
              height={keyBedHeight}
              zIndex={3}
              x={0}
              y={keyBedTopY - 1}
            />
            {renderKeys()}
          </Container>
        </PixiStageWrapper>
      </Box>
    );
  }
);

interface PixiSpriteProps extends _ReactPixi.ISprite {
  x: number;
  y: number;
  height: number;
  width: number;
}
interface PianoKeySpriteProps {
  channelId: string;
  noteNum: number;
  noteOnColors: NoteOnColors;
  isBlackKey: boolean;
  spriteProps: PixiSpriteProps;
  showPianoRoll: boolean;
  showSustainPianoRoll: boolean;
  pianoRollSpeed: number;
  highlightOSMDCursorNotes: boolean;
  cursorHighlightColor: string;
}
interface PianoRollSpriteProps extends PixiSpriteProps {
  key: string;
}
function PianoKeySprite({
  channelId,
  noteNum,
  noteOnColors,
  isBlackKey,
  spriteProps,
  showPianoRoll,
  showSustainPianoRoll,
  pianoRollSpeed,
  highlightOSMDCursorNotes,
  cursorHighlightColor,
}: PianoKeySpriteProps) {
  // keeps track of the piano roll that move upwards
  const [pianoRollSprites, setPianoRollSprites] = useState<
    PianoRollSpriteProps[]
  >([]);
  const [sustainSprites, setSustainSprites] = useState<PianoRollSpriteProps[]>(
    []
  );
  const { pressedColor, sustainedColor } = noteOnColors;
  const noteOn = useTypedSelector((state) =>
    selectNotesOnByChannelId(state, channelId, [noteNum])
  );
  const notePressed = useTypedSelector((state) =>
    selectNotesPressedByChannelId(state, channelId, [noteNum])
  );

  let computedProps = { ...spriteProps };
  if (noteOn) {
    computedProps.tint = notePressed ? pressedColor : sustainedColor;
    if (isBlackKey) {
      computedProps.texture = whiteKeyBorderedTexture;
    }
  } else {
    computedProps.tint = 0xffffff;
    computedProps.texture = spriteProps.texture;
  }

  const isCursorNote = useTypedSelector((state) =>
    selectIsCursorNote(state, noteNum, !highlightOSMDCursorNotes)
  );
  let cursorNoteSprite =
    highlightOSMDCursorNotes && isCursorNote && !notePressed ? (
      <Sprite
        {...computedProps}
        {...(computedProps.zIndex && { zIndex: computedProps.zIndex + 1 })}
        texture={whiteKeyBorderedTexture}
        tint={parseColorToNumber(cursorHighlightColor)}
      />
    ) : null;

  // keep track of dependencies in below useEffect to trigger different logic depending on what changed
  const prevValues = useRef({
    noteOn: false,
    notePressed: false,
    spriteProps,
    pressedColor: noteOnColors.pressedColor,
  }).current;

  // add & remove piano roll notes based on note state and settings
  useEffect(() => {
    // only run this effect if piano roll enabled
    if (!showPianoRoll) return;
    const pianoRollSpriteProps = {
      texture: whiteKeyTexture,
      width: spriteProps.width,
      height: 5000,
      zIndex: isBlackKey ? 2 : 1,
      x: spriteProps.x,
      y: computedProps.y,
    };

    if (showSustainPianoRoll && noteOn !== prevValues.noteOn) {
      if (noteOn === true) {
        // add sprite when note is pressed
        setPianoRollSprites((pianoRollSprites) => [
          ...pianoRollSprites,
          {
            ...pianoRollSpriteProps,
            key: uuidv4(),
            tint: noteOnColors.sustainedColor,
          },
        ]);
      }
      // update height when note is released, such that the bottom of the piano roll note meets the top of the keybed note
      else if (noteOn === false && pianoRollSprites.length > 0) {
        let lastSprite = pianoRollSprites[pianoRollSprites.length - 1];
        if (lastSprite.y && computedProps.y) {
          lastSprite.height = Math.abs(lastSprite.y - computedProps.y);
        }
      }
    }
    if (notePressed !== prevValues.notePressed) {
      if (notePressed === true) {
        // add sprite when note is pressed
        setSustainSprites((sustainSprites) => [
          ...sustainSprites,
          {
            ...pianoRollSpriteProps,
            key: uuidv4(),
            tint: noteOnColors.pressedColor,
          },
        ]);
      }
      // update height when note is released, such that the bottom of the piano roll note meets the top of the keybed note
      else if (notePressed === false && sustainSprites.length > 0) {
        let lastSprite = sustainSprites[sustainSprites.length - 1];
        if (lastSprite.y && computedProps.y) {
          lastSprite.height = Math.abs(lastSprite.y - computedProps.y);
        }
      }
    }
    return () => {
      prevValues.noteOn = noteOn;
      prevValues.notePressed = notePressed;
      prevValues.pressedColor = noteOnColors.pressedColor;
      prevValues.spriteProps = spriteProps;
    };
  }, [
    noteOn,
    notePressed,
    noteOnColors.pressedColor,
    noteOnColors.sustainedColor,
    spriteProps,
    prevValues,
    computedProps.y,
    pianoRollSprites,
    sustainSprites,
    isBlackKey,
    showPianoRoll,
    showSustainPianoRoll,
  ]);

  // update the piano roll notes y value for every tick from Pixi canvas
  useTick((delta) => {
    // only run this effect if piano roll enabled
    if (!showPianoRoll) return;

    let yDelta = delta * pianoRollSpeed;
    setPianoRollSprites((pianoRollSprites) => {
      let updatedSprites: PianoRollSpriteProps[] = [];
      pianoRollSprites.forEach((dSprite) => {
        let newY = dSprite.y - yDelta;
        // only include notes that have not exceeded the top of the canvas
        if (newY + dSprite.height > 0) {
          updatedSprites.push({ ...dSprite, y: newY });
        }
      });
      return updatedSprites;
    });
    setSustainSprites((sustainSprites) => {
      let updatedSprites: PianoRollSpriteProps[] = [];
      sustainSprites.forEach((dSprite) => {
        let newY = dSprite.y - yDelta;
        // only include notes that have not exceeded the top of the canvas
        if (newY + dSprite.height > 0) {
          updatedSprites.push({ ...dSprite, y: newY });
        }
      });
      return updatedSprites;
    });
  });

  return (
    <>
      <Sprite {...computedProps} />
      {cursorNoteSprite}
      {pianoRollSprites.map((x) => (
        <Sprite {...x} />
      ))}
      {sustainSprites.map((x) => (
        <Sprite {...x} />
      ))}
    </>
  );
}

const exportObj: WidgetModule = {
  name: 'Piano',
  Component: Piano,
  SettingComponent: PianoSettings,
  ButtonsComponent: null,
  defaultSettings: {}, // pianoSettings is handled on its own (not using widgetSettings)
  includeBlockSettings: ['Midi Input', 'Color'],
  orderWeight: 10, // used to determine the ordering of the options in the Widget selector
};

export default exportObj;
