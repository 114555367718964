import { GraphQLResult } from '@aws-amplify/api';
import {
  CreateCustomThemeMutation,
  GetCustomThemeQuery,
  ListCustomThemesQuery,
  UpdateCustomThemeMutation,
} from '../API';

interface CustomThemeResponseObj {
  id: string;
  customTheme: string;
  createdAt: string;
  updatedAt: string;
  owner?: string | null | undefined;
}

const mapCustomThemeResp = (
  customThemeRespObj: CustomThemeResponseObj
): CustomTheme => ({
  ...JSON.parse(customThemeRespObj.customTheme),
  id: customThemeRespObj.id,
  createdAt: customThemeRespObj.createdAt,
  updatedAt: customThemeRespObj.updatedAt,
  owner: customThemeRespObj.owner,
});

export function mapListCustomThemesQuery(
  listCustomThemesQuery: GraphQLResult<ListCustomThemesQuery>
): CustomTheme[] {
  return (
    (listCustomThemesQuery.data?.listCustomThemes?.items
      ?.map((customTheme) =>
        customTheme ? mapCustomThemeResp(customTheme) : null
      )
      .filter((x) => x !== null) as CustomTheme[]) || []
  );
}

export function mapGetCustomThemeQuery(
  getCustomThemeQuery: GraphQLResult<GetCustomThemeQuery>
): CustomTheme | null {
  const getCustomThemeRespObj = getCustomThemeQuery.data?.getCustomTheme;
  if (getCustomThemeRespObj) {
    return mapCustomThemeResp(getCustomThemeRespObj);
  }
  return null;
}

export function mapCreateCustomThemeMutation(
  createCustomThemeMutation: GraphQLResult<CreateCustomThemeMutation>
): CustomTheme | null {
  const createCustomThemeRespObj =
    createCustomThemeMutation.data?.createCustomTheme;
  if (createCustomThemeRespObj) {
    return mapCustomThemeResp(createCustomThemeRespObj);
  }
  return null;
}

export function mapUpdateCustomThemeMutation(
  updateCustomThemeMutation: GraphQLResult<UpdateCustomThemeMutation>
): CustomTheme | null {
  const updateCustomThemeRespObj =
    updateCustomThemeMutation.data?.updateCustomTheme;
  if (updateCustomThemeRespObj) {
    return mapCustomThemeResp(updateCustomThemeRespObj);
  }
  return null;
}
